@import "~antd/dist/antd.css";
@import "react-toastify/dist/ReactToastify.css";

.fullscreen-icon {
  background-image: url(assets/leaflet/fullscreen-icon.png);
}
.leaflet-retina .fullscreen-icon {
  background-image: url(assets/leaflet/fullscreen-icon.png);
  background-size: 24px 24px;
}
.marker-cluster {
  height: 90vh;
  width: 90rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #131e2a;
  border-radius: 50%;
  color: #f5f6f6;
  font-weight: bold;
}

.marker-cluster-small {
    height: 150vh;
    width: 100rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #131e2a;
    border-radius: 50%;
    color: #f5f6f6;
    font-weight: bold;
}
